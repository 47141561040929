<template>
  <a-form :form="form" v-bind="formLayout">
    <slot/>
  </a-form>
</template>

<script>
export default {
  name: 'ResponsiveForm',
  props: {
    form: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      formLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
