<template>
  <responsive-form :form="form">
    <!-- 检查是否有 id 并且大于0，大于0是修改。其他是新增，新增不显示主键ID -->
    <a-form-item v-show="model && model.id > 0" label="ID">
      <a-input v-decorator="['id', { initialValue: 0 }]" disabled />
    </a-form-item>
    <a-form-item label="卡片ID">
      <a-input v-decorator="['cardId', {rules: [{required: true}]}]" :disabled="!!model" placeholder="1501016"/>
    </a-form-item>
    <a-form-item label="卡片类型">
      <a-select v-decorator="['cardTypeId', {rules: [{required: true}], initialValue: 6}]" :disabled="!!model">
        <a-select-option v-for="item in cardType" :key="item.id" :value="item.id">
          {{ item.id }} {{ item.label }} {{ item.disabled ? '(未使用)' : '' }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="角色ID">
      <a-input v-decorator="['charaId', {rules: [{required: true}]}]" placeholder="150101"/>
    </a-form-item>
    <a-form-item label="地图ID">
      <a-input v-decorator="['mapId', {rules: [{required: true}]}]" placeholder="150001" />
    </a-form-item>
    <a-form-item label="开始日期">
      <a-date-picker show-time v-decorator="['startDate', {rules: [{required: true}], initialValue: now}]" />
    </a-form-item>
    <a-form-item label="结束日期">
      <a-date-picker show-time v-decorator="['endDate', {rules: [{required: true}], initialValue: end}]" />
    </a-form-item>
  </responsive-form>
</template>

<script>
import pick from 'lodash.pick'
import moment from 'moment'
import ResponsiveForm from '@/components/ResponsiveForm/ResponsiveForm'
import { Mai2DxPassType } from '@/model/mai2const'

const now = moment().set({ hour: 0, minute: 0, second: 0 })
// 表单字段
const fields = ['id', 'cardId', 'cardTypeId', 'charaId', 'mapId', 'startDate', 'endDate']
export default {
  components: { ResponsiveForm },
  props: {
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      now: now,
      end: now.clone().add({ day: 15 }),
      form: this.$form.createForm(this),
      cardType: Mai2DxPassType
    }
  },
  created () {
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  }
}
</script>
