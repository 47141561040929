import moment from 'moment'

export const Today = moment().set({ hour: 0, minute: 0, second: 0 })
export const Next15Day = Today.clone().add({ day: 15 })

export const Mai2ItemKind = [
  { id: 0, label: 'Start' },
  { id: 1, label: '牌子' },
  { id: 2, label: '称号' },
  { id: 3, label: '头像' },
  { id: 4, label: '奖励' },
  { id: 5, label: '歌曲' },
  { id: 6, label: '歌曲 Master难度' },
  { id: 7, label: '歌曲 ReMaster难度' },
  { id: 8, label: '歌曲 Strong(未使用)' },
  { id: 9, label: '角色' },
  { id: 10, label: '伙伴' },
  { id: 11, label: '底板' },
  { id: 12, label: '票' }
]

export const Mai2ItemPreset = [
  {
    id: 0,
    name: '模板选择',
    itemKind: 0,
    itemId: 0,
    stock: 0
  },
  {
    id: 1,
    name: '歌曲 heavenly blast',
    itemKind: 5,
    itemId: 11223,
    stock: 1
  }
]

export const Mai2GameVersionPreset = [
  {
    id: 0,
    name: '模板选择',
    lastRomVersion: '',
    lastDataVersion: ''
  },
  {
    id: 1,
    name: '1.19',
    lastRomVersion: '1.19.06',
    lastDataVersion: '1.15.06'
  }
]

export const Mai2DxPassType = [
  { id: 0, label: 'NO', disabled: true },
  { id: 1, label: 'EXPIRED' },
  { id: 2, label: 'BRONZE', disabled: true },
  { id: 3, label: 'SILVER', disabled: true },
  { id: 4, label: 'GOLD' },
  { id: 5, label: 'PLATINUM', disabled: true },
  { id: 6, label: 'FREEDOM' }
]

export const Mai2NetMember = [
  { id: 0, label: '0: NO' },
  { id: 1, label: '1: FREE' },
  { id: 2, label: '2: STANDARD' },
  { id: 3, label: '3: FREE PLAY' }
]

export const Mai2CardPreset = [
  {
    presetId: 0,
    name: '模板选择',
    cardId: 0,
    cardTypeId: 0,
    charaId: 0,
    mapId: 0,
    startDate: Today,
    endDate: Next15Day
  },
  {
    presetId: 1,
    name: 'FREEDOM PASS',
    cardId: 1501016,
    cardTypeId: 6,
    charaId: 150101,
    mapId: 150001,
    startDate: Today,
    endDate: Next15Day
  }
]

export const Mai2MusicGenre = [
  'maimai',
  'niconicoボーカロイド',
  'POPSアニメ',
  'オリジナル',
  'オンゲキCHUNITHM',
  'ゲームバラエティ',
  'バラエティ',
  '東方Project'
]

export const Mai2MusicAddVersion = [
  'maimai',
  'maimaiPLUS',
  'GreeN',
  'GreeNPLUS',
  'ORANGE',
  'ORANGEPLUS',
  'PiNK',
  'PiNKPLUS',
  'MURASAKi',
  'MURASAKiPLUS',
  'MiLK',
  'MiLKPLUS',
  'FiNALE',
  'maimaDX',
  'maimaDXPLUS',
  'Splash',
  'SplashPLUS'
]
